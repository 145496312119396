import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import loadable from "@loadable/component"

import {
  TITLES,
  OUR_SERVICES,
  SERVICES,
  INDUSTRIES,
  IMAGES,
} from "../data/ai-ml-software-development"
import { IMG } from "../const"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/ai-ml-software-development.module.scss"
import Headline from "../components/headline"
import TypingText from "../components/typing-text"
import ServicesList from "../components/services-list"
import IndustryCard from "../components/ai-ml-software-development/IndustryCard"
import ServiceCard from "../components/carousels/ServiceCard"

const TitleBlock = loadable(() => import("../components/title-block"))
const ServicesCarousel = loadable(() =>
  import("../components/carousels/ServicesCarousel")
)
const ContactForm = loadable(() => import("../components/contact-form"))

const AiMlSoftwareDevelopment = () => {
  return (
    <Layout
      pageTitle="Artificial Intelligence & Machine Learning Services"
      metaDescription="Explore LaSoft's AI & ML development services to transform your business with innovative solutions, expert insights, and cutting-edge technology."
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-AI-software-dev.jpg`}
    >
      {({ width }) => {
        return (
          <main className={cx(styles.aiBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className="strongText">We offer you to try LaSoft’s</p>
                    <h1 className="withIndent">
                      <strong>AI & ML software</strong>
                      <br />
                      development <br />
                      services
                    </h1>

                    <div className="rightColumn">
                      <p>
                        Considering your market niche's specifics, we can
                        develop digital solutions from scratch for your business
                        using AI and ML as a service.
                      </p>
                      <div>
                        <Link
                          to="#contact-form"
                          className="goldFatLink ai-estimate"
                        >
                          Project Estimate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Headline>
              {/* ------ TypingText ------ */}
              <section className="section">
                <div className={styles.dialogWrapper}>
                  <div className={styles.dialogText}>
                    <TypingText />
                  </div>
                  <div className={styles.dialogImg}>
                    <picture>
                      <source
                        srcSet={`${IMG.AI_ML_SOFTWARE}/ws.avif,	${IMG.AI_ML_SOFTWARE}/ws@2x.avif 2x, ${IMG.AI_ML_SOFTWARE}/ws@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.AI_ML_SOFTWARE}/ws.webp,	${IMG.AI_ML_SOFTWARE}/ws@2x.webp 2x, ${IMG.AI_ML_SOFTWARE}/ws@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`${IMG.AI_ML_SOFTWARE}/ws.png, ${IMG.AI_ML_SOFTWARE}/ws@2x.png 2x,${IMG.AI_ML_SOFTWARE}/ws@3x.png 3x`}
                      />
                      <img
                        src={`${IMG.AI_ML_SOFTWARE}/ws.jpg`}
                        alt="William Shakespeare"
                        loading="lazy"
                        width="495"
                        height="493"
                      />
                    </picture>
                  </div>
                </div>
              </section>

              <section className="section">
                <TitleBlock options={TITLES.industries} />
                <ul className={styles.industries}>
                  {INDUSTRIES.map((item, index) => (
                    <IndustryCard key={index} item={item} />
                  ))}
                </ul>
              </section>

              <section className={cx(styles.benefits, "section")}>
                <TitleBlock options={TITLES.benefits} />
                {width > 900 && (
                  <div className={styles.carouselWrapper}>
                    <ServicesCarousel list={SERVICES} />
                  </div>
                )}
                {width > 0 && width <= 900 && (
                  <div className={styles.cardWrapper}>
                    {SERVICES.map(item => (
                      <ServiceCard key={item.id} item={item} />
                    ))}
                  </div>
                )}
                <ul className={styles.imgContainer}>
                  {IMAGES.map(item => (
                    <li key={item.id}>
                      <picture>
                        <source
                          srcSet={`${IMG.AI_ML_SOFTWARE}/${item.id}.avif,	${IMG.AI_ML_SOFTWARE}/${item.id}@2x.avif 2x, ${IMG.AI_ML_SOFTWARE}/${item.id}@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.AI_ML_SOFTWARE}/${item.id}.webp,	${IMG.AI_ML_SOFTWARE}/${item.id}@2x.webp 2x, ${IMG.AI_ML_SOFTWARE}/${item.id}@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.AI_ML_SOFTWARE}/${item.id}.png, ${IMG.AI_ML_SOFTWARE}/${item.id}@2x.png 2x,${IMG.AI_ML_SOFTWARE}/${item.id}@3x.png 3x`}
                        />
                        <img
                          src={`${IMG.AI_ML_SOFTWARE}/${item.id}.png`}
                          alt="Screenshot"
                          loading="lazy"
                          width="270"
                          height="580"
                        />
                      </picture>
                    </li>
                  ))}
                </ul>
              </section>
              <section className="section">
                <TitleBlock options={TITLES.services} />
                <ServicesList services={OUR_SERVICES.items} />
              </section>
            </article>

            <section className="section">
              <div className="footerForm common-kit" id="contact-form">
                <ContactForm formStatID="CommonFooter" />
              </div>
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default AiMlSoftwareDevelopment
