import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import {
  OPTIONS_LIST,
  DESIGN_SERVICES,
  TITLES,
  SERVICES,
} from "../data/product-design"
import { IMG } from "../const"
import Layout from "../components/layout"
import "../assets/styles/commons/_common-kit.scss"
import Headline from "../components/headline"
import OptionsList from "../components/options-list"
import TextAndScheme from "../components/product-design/TextAndScheme"
import ServicesList from "../components/services-list"
import CaseStudies from "../components/case-studies"
import ColoredSection from "../components/colored-section"
import * as styles from "../assets/styles/product-design.module.scss"

const TitleBlock = loadable(() => import("../components/title-block"))
const Accordion = loadable(() => import("../components/accordion"))
const ContactForm = loadable(() => import("../components/contact-form"))

const ProductDesign = () => {
  return (
    <Layout
      pageTitle="Product Design Service"
      defaultImage={`${IMG.THUMBNAILS}/thumbnails-digital-product-design.jpg`}
    >
      {({ width }) => {
        return (
          <>
            <main className={cx(styles.productDesignBlock, "common-kit")}>
              <article>
                <Headline>
                  <div className={cx(styles.headline, "headline")}>
                    <div className={cx(styles.headline_grid, "headline_grid")}>
                      <p className={"strongText"}>
                        we bring ideas to life through
                      </p>
                      <h1 className={"withIndent"}>
                        <strong>Digital</strong> <br />
                        product&nbsp;design <br />
                        service
                      </h1>
                      <div className={"rightColumn"}>
                        <p>
                          Use our product design services to create a unique
                          product that brings value.
                        </p>
                        <div className="onDesktop">
                          <Link
                            to="#contact-form"
                            className="goldFatLink design-talk"
                          >
                            Let’s talk
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className={styles.flexBlock}>
                      <div className={styles.strongTextBlock}>
                        <h3 className={styles.strongText}>
                          our digital product design service is for:
                        </h3>
                      </div>
                      <OptionsList list={OPTIONS_LIST} />
                    </div>

                    <div className="onMobile">
                      <Link
                        to="#contact-form"
                        className="goldFatLink design-talk"
                      >
                        Let’s talk
                      </Link>
                    </div>
                  </div>
                </Headline>

                <TextAndScheme title={TITLES.t_shape_designers} />

                <section className={"section"}>
                  <TitleBlock options={TITLES.services} />
                  <ServicesList services={SERVICES.items} />
                </section>

                <CaseStudies title={TITLES.case_studies} screenWidth={width} />

                <section className={cx("section", styles.accordionBlock)}>
                  <TitleBlock options={TITLES.accordion} />
                  <div className={styles.accordionWrapper}>
                    <Accordion options={DESIGN_SERVICES} />
                  </div>
                </section>

                <ColoredSection options={TITLES.colored} />
              </article>
            </main>
            <section className="footerForm common-kit">
              <div className="section" id="contact-form">
                <ContactForm formStatID="CommonFooter" />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default ProductDesign
