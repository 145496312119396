import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import PropTypes from "prop-types"

import "../../assets/styles/commons/_common__scrollbars.scss"
import * as styles from "./colored-section.module.scss"

// Use classes "colored-section-title", "colored-section-img" inside the parent wrapper's scss-file to change styles

const ColoredSection = ({ options, isTest }) => (
  <section
    className={cx(styles.colored, "common__thinScrollbar__bgGold")}
    {...(options.industry && { "data-industry": options.industry })}
  >
    <div>
      <div className={styles.flexBlock}>
        <div className={styles.strongTextBlock}>
          <p className={styles.strongText}>{options.strongText}</p>
        </div>
        <div className={styles.titleBlock}>
          <h2 className={cx(styles.withIndent, "colored-section-title")}>
            {options.title}
          </h2>
          <p className={styles.uppercase}>
            <Link
              to={options.link}
              {...(isTest
                ? { className: "mvp-yellow-block" }
                : { className: options.linkClassName || "" })}
            >
              {isTest ? "Get Project Quote" : options.uppercase}
            </Link>
            <span
              className={styles.extraBg}
              {...(options.industry && { "data-industry": options.industry })}
            />
          </p>
        </div>
      </div>
      <div
        className={cx(
          styles.img,
          "colored-section-img",
          options.industry ? "" : styles.imgMt
        )}
      >
        <picture>
          <img
            src={options.imageDesktop}
            alt="Scheme"
            width={
              options.imageDesktopWidth ? options.imageDesktopWidth : "1208"
            }
            height={
              options.imageDesktopHeight ? options.imageDesktopHeight : "111"
            }
            loading="lazy"
          />
        </picture>
      </div>
    </div>
  </section>
)

export default ColoredSection

ColoredSection.propTypes = {
  options: PropTypes.object.isRequired,
}
