import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import { TITLES, SERVICES, INTEGRATIONS } from "../data/insurtech-software"
import Layout from "../components/layout"
import { IMG } from "../const"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/insurtech-software.module.scss"

const Headline = loadable(() => import("../components/headline"))
const TitleBlock = loadable(() => import("../components/title-block"))
const Testimonials = loadable(() => import("../components/testimonials"))
const ServicesList = loadable(() => import("../components/services-list"))
const SimpleIntegrations = loadable(() =>
  import("../components/integrations-block/SimpleIntegrations")
)
const ColoredSection = loadable(() => import("../components/colored-section"))
const ContactForm = loadable(() => import("../components/contact-form"))

const InsurTechSoftwareDevelopment = () => (
  <Layout
    pageTitle="InsurTech Software Development"
    defaultImage={`${IMG.THUMBNAILS}/thumbnail-insurance.jpg`}
  >
    {() => {
      return (
        <>
          <main className={cx(styles.insurTechBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className="strongText">welcome to</p>
                    <h1 className="withIndent">
                      <strong>
                        InsurTech
                        <br /> software
                      </strong>
                      <br />
                      development
                    </h1>
                    <div className="positionedText">
                      <p>
                        receive on-time high-quality software that meets your
                        specific goal
                      </p>
                    </div>
                    <div className="rightColumn">
                      <p>
                        Since 2014, we have designed, developed, and delivered
                        high-quality digital insurance tech products to market
                        that solve clients' goals and increase their business
                        profits.
                      </p>
                      <div className="onDesktop">
                        <Link
                          to="#contact-form"
                          className="goldFatLink insuretech-contact"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="onMobile">
                    <Link
                      to="#contact-form"
                      className="goldFatLink insuretech-contact"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </Headline>

              <section className={cx(styles.services, "section")}>
                <TitleBlock options={TITLES.services} />
                <ServicesList services={SERVICES.items} />
              </section>

              <SimpleIntegrations
                title={TITLES.integrations}
                options={INTEGRATIONS}
              />

              <Testimonials title={TITLES.testimonials} />

              <ColoredSection options={TITLES.colored} />
            </article>
          </main>
          <section className="footerForm common-kit">
            <div className="section" id="contact-form">
              <ContactForm formStatID="CommonFooter" />
            </div>
          </section>
        </>
      )
    }}
  </Layout>
)

export default InsurTechSoftwareDevelopment
